.websiteLinkWrapper {
    background-color: #0a3758;
    padding: 1em;
    text-align: center;
    font-size: 18px;

    .websiteLink {
        color: var(--color-white);
        font-weight: 700;
    }
}

.top-section {
    background: url('https://www.internal-displacement.org/sites/default/files/GRID2023ComingSoonImage.jpg');
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    background-attachment: fixed;

    .overlay {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        padding: 12em 2em;
    }

    .top-content-wrapper {
        max-width: 810px;
        margin: 0 auto;

        .logo-wrapper {
            text-align: center;
            margin-bottom: 3em;

            .logo {
                height: 3.25em;
                object-fit: contain;
                margin: 0 auto;
            }
        }

        .heading1 {
            font-size: 3.75em;
            color: var(--color-white);
            text-align: center;
            font-family: "freight-big-pro",serif;
            line-height: 1;
            margin-bottom: 0.5em;
        }

        .heading2 {
            font-size: 2em;
            color: var(--color-white);
            text-align: center;
            font-family: "freight-big-pro",serif;
        }

        .info {
            margin: 3em 0 1.25em;
            font-size: 1.25em;
            line-height: 1.4;
            color: var(--color-white);
            text-align: center;
        }

        .top-button-wrapper {
            display: flex;
            justify-content: center;
            gap: 2em;
            margin-top: 2em;

            .register-here-button {
                border: none;
                color: #0a3758;
                padding: 1em 1.5em;
                background-color: var(--color-white);
                text-decoration: none;
                border-radius: 0.25em;
                font-size: 1.25em;
                transition: 0.4s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.bottom-section {
    width: 100%;
    background-color: var(--color-white);
    padding: 3em 2em;

    .bottom-content-wrapper {
        max-width: 810px;
        margin: 0 auto;

        .text {
            font-size: 1.25em;
            line-height: 1.4;
            list-style: none;
        }

        .list-heading {
            margin: 1.25em 0 0;
        }

        .infoBox {
            margin-top: 1.5em;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            padding: 1.5em 2em;
            border-radius: 0.5em;

            .list-title {
                font-size: 1.25em;
                line-height: 1.4;
                list-style: none;
                margin-bottom: 0.75em;
                font-family: "freight-big-pro",serif;
                color: #0a3758;
            }

            .eventList {
                margin-bottom: 1.5em;

                td {
                    list-style: none;
                    font-size: 1.25em;
                    line-height: 1.2;
                    flex: 1 auto;
                    vertical-align: top;
                    padding: 0.25em 0;
                }

                .keyWrapper {
                    padding-right: 0.75em;
                }
            }

            .programmeList {
                display: flex;
                flex-direction: column;
                gap: 1em;

                li {
                    list-style: none;
                    list-style-position: outside;
                    font-size: 1.25em;
                    line-height: 1.3;
                }

                .nestedListItem {
                    list-style: none;

                    .nestedList {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5em;

                        li {
                            font-size: 1em;
                            list-style-type: disc;
                            margin-left: 1.25em;
                        }
                    }
                }
            }
        }
    }

    .buttonWrapper {
        margin-top: 2em;
        display: flex;
        justify-content: center;

        .register-button {
            background-color: #0a3758;
            padding: 1em 1.5em;
            color: var(--color-white);
            text-decoration: none;
            border-radius: 0.25em;
            transition: 0.4s;
            font-size: 1.25em;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .bottom-link {
        margin-top: 2em;
        font-size: 1.25em;
        line-height: 1.2;
        color: #121212;

        .grid-link {
            margin-left: 0.5em;
            font-size: 0.8em;
            text-align: center;
            color: #0d6efd;
            font-weight: 700;
            letter-spacing: 0.025em;
        }
    }
}
