@import url("https://use.typekit.net/ret0ejy.css");

:root {
    /* Colors */
    --color-white: #fff;
}

* {
    margin: 0;
    padding: 0;
    font-family: "proxima-nova",sans-serif;

    @media only screen and (max-width: 991px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 719px) {
        font-size: 13px;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

b {
    font-weight: 700;
}
